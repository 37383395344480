
    import {computed, defineComponent, onMounted, reactive, toRefs,ref} from 'vue';
    import apiTalent from "@/request/apis/api_talent";
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import {IArticleItem} from "@/models/article_model";
    import ApiArticle from "@/request/apis/api_article";

    interface IState {
        list: any,
        visible: any,
        level:any,
        title: any,
        name: any,
        total: any,
        searches: any,
        editId: any,
        type: any,
        addId: any,
        editPid:any,
        visible2:any,
        sonData:any,
        sn:any,
        ids:any,
    }


    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                visible: false,
                title: '',
                name: '',
                list: [],
                total: 0,
                searches: {
                    name: ''
                },
                editId: '',
                type: '',
                addId: '',
                ids:'',
                editPid:'',
                visible2:false,
                sonData:[],
                sn:'',
                level:''
            })
            const columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    title: '编码',
                    dataIndex: 'sn',
                    key: 'sn',
                    className:"my"
                },
                {
                    title: '专业',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                },
                {
                    title: '添加时间',
                    dataIndex: 'create_time',
                    key: 'create_time',
                },
                {
                    title: '最后修改时间',
                    dataIndex: 'update_time',
                    key: 'update_time',
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    width: 220,
                    align:'right'
                },
            ]

            const columns2 = [
                {
                    title: '编码',
                    dataIndex: 'sn',
                    key: 'sn',
                    className:"my"
                },
                {
                    title: '证书名称',
                    dataIndex: 'name',
                    key: 'name',
                    className:"my"
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                    className:"my"
                },
                {
                    title: '添加时间',
                    dataIndex: 'create_time',
                    key: 'create_time',
                    className:"my"
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    width: 150,
                    className:"my"
                },
            ]

            const add = () => {
                state.title = '新增证书所属专业'
                state.name = ''
                state.sn = ""
                state.visible = true
                state.level = 1
                state.editPid =''
                state.type = 1
            }

            const addSon = (val) => {
                state.title = '添加证书'
                state.name = ''
                state.sn = ''
                state.visible = true
                state.addId = val.id
                state.editPid =''
                state.type = 3
                state.level = Number(state.level)+1
                console.log(val)
            }

            const edit = (val) => {
                state.type = 2
                state.editId = val.id
                state.name = val.name
                state.sn = val.sn
                state.ids = val.ids
                state.title = '编辑证书'
                state.level = val.level
                state.editPid =val.pid
                state.visible = true
                state.visible2 = false

            }
            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));


            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const getList = async () => {
                const params = {...state.searches}
                const res = await apiTalent.certificateList(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.list = data
                    state.list= JSON.parse(JSON.stringify(state.list).replace(/"son"/g,"\"children\""))
                }
            }


            const handleOk = async () => {
                if (state.type == 1) {
                    const params = {name: state.name,sn:state.sn,level:state.level}
                    const res = await apiTalent.certificateAdd(params)
                    if (res.error_code == 0) {
                        message.success(res.msg)
                        state.visible = false
                        await getList()
                    }else{
                        message.error(res.msg)
                    }
                }
                if (state.type == 2) {
                    const params = {name: state.name, id: state.editId,pid:state.editPid,sn:state.sn,ids:state.ids,level:state.level}
                    const res = await apiTalent.certificateEdit(params)
                    if (res && res.error_code == 0) {
                        message.success(res.msg)
                        state.visible = false
                        state.visible2 = false
                        await getList()
                    }
                }
                if (state.type == 3) {
                    const params = {name: state.name, pid: state.addId,sn:state.sn,level:state.level}
                    const res = await apiTalent.certificateAdd(params)
                    if (res.error_code == 0) {
                        message.success(res.msg)
                        state.visible = false
                        await getList()
                        await getList()
                    }else{
                        message.error(res.msg)
                    }
                }
            }

            const onDel = async (val, val2) => {
                const params = {id: val.id, status: val2,pid:state.editPid}
                const res = await apiTalent.certificateStatus(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success('设置成功')
                        state.visible2 = false
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }

            const onSearch = () => {
                getList()
            }

            const onReset = () => {
                state.searches = {
                    name: ''
                }
                getList()
            }

            const open = (val) =>{
                state.visible2 = true
                state.editPid = val.id
                state.sonData = val.son
            }

            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                columns,
                open,
                handleOk,
                onSearch,
                addSon,
                columns2,
                onReset,
                onDel,
                edit,
                add,
                pagination,
                handleTableChange
            }
        }
    });
